:root {
  --navhc: #6D7177;
}

/* ----- ----- ---- */
/* NAV              */
/* ----- ----- ---- */

#nav {
  position: fixed;
  width: calc(var(--unit) * 10);
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  animation-timing-function: var(--ttf);
  transform: translateX(-90%);
  transition-duration: 0.5s;
  display: flex;
}

#nav::before {
  content: '';
  width: calc(var(--unit) * 1);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  z-index: -2;
  transition-duration: 0.5s;
}

#nav:after {
  content: '';
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e3e3e3;
  z-index: -1;
}

.nav-open {
  transform: translateX(0) !important;
}

.nav-open::before {
  opacity: 1 !important;
  pointer-events: inherit !important;
}

@media only screen and (max-width: 800px) {
  #nav {
    width: 100vw;
    transform: translateX( calc(-100% + 50px) ); /* closed */
  }
}



/* ----- ----- ---- */
/* NAV CONTENT      */
/* ----- ----- ---- */

#sidebar {
  position: absolute;
  right: 0;
  top: 0;
  width: 10%;
  height: 100vh;
}

#nav-toggle {
  width: var(--nav-icon-width);
  padding: 50px 0;
  margin: auto;
  height: calc( var(--nav-icon-width) * var(--nav-offset));
}

#toggle-text {
  writing-mode: vertical-lr;
  line-height: 50px;
  margin: 0;
  padding: 0;
  color: #2B2D30;
  transform: translateX(-4px) translateY(0);
}

#nav-links {
  width: fit-content;
  height: fit-content;
  margin: auto;
  align-self: center;
}

#nav-links h2 {
  color: #2B2D30;
  text-decoration: none;
  transition-duration: 0.25s;
  margin-top: 20px;
  font-size: 28px;
  line-height: 60px;
}

#nav-links h2:hover {
  color: var(--navhc);
}

@media only screen and (max-width: 800px) {
  #sidebar {
    width: 50px;
    height: 100vh;
  }
}



/* ----- ----- ----- ----- */
/* ICON TRANSITION         */
/* ----- ----- ----- ----- */

#nav-toggle:hover polygon {
  fill: var(--navhc) !important;
}

#nav-toggle span {
  display: block;
  position: absolute;
  width: var(--nav-icon-width);
  height: calc(var(--nav-icon-width) * var(--nav-offset));
  transition-duration: 0.5s;
  transition-delay: 0.25s;
}

#nav-toggle svg {
  display: block;
  position: absolute;
  transition-duration: 0.5s;
  transition-delay: 0.25s;
}

#nav-toggle polygon {
  transition-duration: 0.25s;
  transition-delay: 0 !important;
}

#toggle-1 { transform: translate(0px, 0px); }
.toggle-1-open { transform: translateY(calc( (var(--nav-icon-width) * var(--nav-offset) / 2) - 0.5px ) ) !important; }
.toggle-1-open svg { transform: rotate(45deg); }

#toggle-2 { transform: translateY(calc( (var(--nav-icon-width) * var(--nav-offset)) - 0.5px ) ); }
.toggle-2-open { transform: translateY(calc( (var(--nav-icon-width) * var(--nav-offset) / 2) - 0.5px ) ) !important; }
.toggle-2-open svg {transform: rotate(-45deg); }

#toggle-3 { transform: translateY(calc( (var(--nav-icon-width) * var(--nav-offset) / 2) - 0.5px ) ) !important; }
.toggle-3-open svg { transform: scaleX(0) !important; }