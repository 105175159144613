:root {
  --unit: 50px;
  --background-shift: calc(50% - 13px);

  --dot-size: 1px;

  --grid-moz: -moz-radial-gradient(var(--dot-color) var(--dot-size), transparent var(--dot-size)), -moz-radial-gradient(var(--dot-color) var(--dot-size), transparent var(--dot-size)),yellow;
  --grid-webkit: -webkit-radial-gradient(var(--dot-color) var(--dot-size), transparent var(--dot-size)), -webkit-radial-gradient(var(--dot-color) var(--dot-size), transparent var(--dot-size)), var(--theme-color);

  --ttf: cubic-bezier(.68,0,.32,1);
  --nav-icon-width: 20px;
  --nav-offset: .7778
}

* {
  margin: 0;
  cursor: none;
}

::-webkit-scrollbar {
  display: none;
}

html {
  height: auto;
}

body {
  margin: 0;
  padding: 0 0 calc(var(--unit) * 4) 0;
  background: var(--grid-moz);
  background: var(--grid-webkit);
  background-position: calc(50% - (var(--unit)/2)) calc(var(--unit) / 2);
  -webkit-background-size: var(--unit)  var(--unit);
  -moz-background-size: var(--unit)  var(--unit);
  background-size: var(--unit)  var(--unit);
  background-attachment: scroll;
  height: auto;
  transition-duration: 1s;
}

#main-spacer {
  width: 100%;
  height: calc(var(--unit) * 2);
}

h1 {
  font-size: 60px;
  line-height: 60px;
  font-family: 'univers_cond';
  font-weight: 300;
  color: white;
}

h2 {
  font-size: 35px;
  line-height: 35px;
  font-family: 'univers_cond';
  font-weight: 300;
  color: white;
  text-transform: uppercase;
}


h3 {
  font-size: 25px;
  line-height: 35px;
  font-family: 'univers_lght';
  font-weight: 300;
  color: white;
  margin-bottom: 10px;
}

h4 {
  font-size: 14px;
  line-height: 35px;
  font-family: 'univers_lght_obl';
  font-weight: 300;
  color: white;
}

p, li {
  font-size: 13px;
  line-height: 34px;
  font-family: 'univers_lght';
  font-weight: 300;
  color: white;
}

i, label {
  font-size: 13px;
  line-height: 21px;
  font-family: 'univers_lght_obl';
  font-weight: 300;
  color: white;
}

img {
  width: 100%;
}

ul, ol {
  padding-inline-start: 0;
}

input, textarea {
  font-size: 13px;
  line-height: 13px;
  font-family: 'univers_lght';
  font-weight: 300;
  color: white;
}

#grid {
  width: calc(22 * var(--unit));
  margin: var(--unit) auto 0 auto;
}

.vertical {
  writing-mode: vertical-rl;
}


/* ----- ----- ---- ----- */
/* MEDIA QUERIES          */
/* ----- ----- ---- ----- */

/* 
desktop: 1920-1200
tablet: 1200-750
mobile: 750-- 
*/

/* tablet styling */
@media only screen and (max-width: 1200px) {
  #grid {
    width: calc(14 * var(--unit));
  }

  .project {
    margin-bottom: calc(2 * var(--unit));
  }

  .project .project-image {
    flex: 0 0 100%;
    height: calc(4 * var(--unit));
    margin-bottom: var(--unit);
  }

  .project .project-text {
    flex: 0 0 100%;
    margin-bottom: var(--unit);
  }
}

@media only screen and (max-width: 800px) {
  #grid {
    width: calc(100% - 50px);
  }
}




/* ----- ----- ---- ----- */
/* FONTS                  */
/* ----- ----- ---- ----- */

@font-face {
  font-family: 'univers_lght_obl';
  src: url('/univers/Univers-LightOblique.eot');
  src: url('/univers/Univers-LightOblique.eot?#iefix') format('embedded-opentype'),
      url('/univers/Univers-LightOblique.woff2') format('woff2'),
      url('/univers/Univers-LightOblique.woff') format('woff'),
      url('/univers/Univers-LightOblique.ttf') format('truetype'),
      url('/univers/Univers-LightOblique.svg#Univers-LightOblique') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'univers_lght';
  src: url('/univers/Univers-Light.eot');
  src: url('/univers/Univers-Light.eot?#iefix') format('embedded-opentype'),
      url('/univers/Univers-Light.woff2') format('woff2'),
      url('/univers/Univers-Light.woff') format('woff'),
      url('/univers/Univers-Light.ttf') format('truetype'),
      url('/univers/Univers-Light.svg#Univers-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'univers_cond';
  src: url('/univers/Univers-LightUltraCondensed.eot');
  src: url('/univers/Univers-LightUltraCondensed.eot?#iefix') format('embedded-opentype'),
      url('/univers/Univers-LightUltraCondensed.woff2') format('woff2'),
      url('/univers/Univers-LightUltraCondensed.woff') format('woff'),
      url('/univers/Univers-LightUltraCondensed.ttf') format('truetype'),
      url('/univers/Univers-LightUltraCondensed.svg#Univers-LightUltraCondensed') format('svg');
  font-weight: 300;
  font-style: normal;
}



/* ----- ----- ---- ----- */
/* SPINNER                */
/* ----- ----- ---- ----- */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: white;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* ----- ----- ---- ----- */
/* TEXT COLUMNS           */
/* ----- ----- ---- ----- */

.text-columns {
  column-count: 2;
  column-gap: var(--unit);
}

@media only screen and (max-width: 1200px) {
  .text-columns {
    column-count: 1;
    column-gap: var(--unit);
  }
}
