
/* ----- ----- ---- ----- */
/* PROJECTS               */
/* ----- ----- ---- ----- */

.project {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: calc(var(--unit) * 4) !important;
  margin-bottom: calc(var(--unit) * 2);
  transition-duration: 1s;
}

.project-image {
  flex: 0 0 50%;
  height: inherit;
  background-size: cover;
  background-position: center;
  opacity: 0;
  animation: textFadeUp 0.75s var(--delay) forwards;
  animation-timing-function: var(--ttf);
}

.project-text {
  flex: 0 0 calc(50% - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  opacity: 0;
  animation: textFadeUp 0.75s var(--delay) forwards;
  animation-timing-function: var(--ttf);
}

.project-text .title, .project-text .info {
  flex: 0 0 25%;
  display: inline;
}

.info {
  margin-left: 40px;
}

.project-text .description {
  flex: 0 0 40%;
}

.project-text .description.full-width {
  flex: 0 0 calc(25% + 40%);
}

.project-footer {
  height: 20px;
  width: 50%;
  
  transition-duration: 0.25s;
  transform-origin: left center;
  transform: scaleX(0);
  opacity: 0;
  animation-timing-function: var(--ttf);
}

.project:hover .project-footer {
  transform: scaleX(1);
  opacity: 1;
}



/* ----- ----- ---- ----- */
/* SCROLL-HOVER BEHAVIOR  */
/* ----- ----- ---- ----- */

.in-view {
  opacity: 1;
}

.out-view {
  opacity: 0.1;
}

.is-hovered {
  opacity: 1;
}
.is-not-hovered {
  opacity: 0.1;
}

@media only screen and (max-width: 1200px) {
  .in-view, .out-view, .is-hovered, .is-not-hovered {
    opacity: 1;
  }
}


/* ----- ----- ---- ----- */
/* LOCALPOST              */
/* ----- ----- ---- ----- */

#localpost-loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: +1;
  padding: 89px;
}

#localpost #overview-canvas {
  position: absolute;
  right: 0;
  margin: auto;
}

#localpost #coaster-canvas {
  position: absolute;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 800px) {
  .localpost1 {
    margin-top: 200px !important;
  }
  .localpost3 {
    margin-top: 400px !important;
  }
  .lpParagraph2 {
    margin-top: -75px !important;
  }
  .lpParagraph3 {
    margin-top: -1000px !important;
  }
  .lpParagraph4 {
    margin-top: -900px !important;
  }
  .lpParagraph5 {
    margin-top: -100px !important;
  }
  .lpParagraph6 {
    margin-top: -75px !important;
  }
  .lpParagraph7 {
    margin-top: -900px !important;
  }
  .lpParagraph8 {
    margin-top: -850px !important;
  }
}

@media only screen and (max-width: 700px) {
  
  .localpost1 {
    margin-top: 200px !important;
  }
  .localpost3 {
    margin-top: 500px !important;
  }
  .lpParagraph2 {
    margin-top: -75px !important;
  }
  .lpParagraph3 {
    margin-top: -900px !important;
  }
  .lpParagraph4 {
    margin-top: -825px !important;
  }
  .lpParagraph7 {
    margin-top: -850px !important;
  }
  .lpParagraph8 {
    margin-top: -750px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 801px) {
  .localpost1 {
    margin-top: 200px !important;
  }
  .localpost2 {
    margin-top: 0px !important;
  }
  .localpost3 {
    margin-top: 500px !important;
  }
  .lpParagraph1 {
    margin-top: 50px !important;
  }
  .lpParagraph3 {
    margin-top: -1000px !important;
  }
  .lpParagraph4 {
    margin-top: -1000px !important;
  }
  .lpParagraph7 {
    margin-top: -1000px !important;
  }
  .lpParagraph8 {
    margin-top: -1000px !important;
  }

}





/* ----- ----- ---- ----- */
/* METRO                  */
/* ----- ----- ---- ----- */

#metro * {
  color: #2B2D30;
}

#metro .row {
  margin-top: 100px;
}

#metro .video-wrapper {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 9);
}

#metro .video-wrapper iframe {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 9);
  border: none;
}

@media only screen and (max-width: 1200px) and (min-width: 801px) {
  .metro1 {
    margin-top: 150px !important;
  }
  .metroParagraph1 {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 800px) {
  .metro1 {
    margin-top: 150px !important;
  }
  .metro2 {
    margin-top: 50px !important;
  }
  .metro3 {
    margin-top: 0px !important;
  }
  .metro4 {
    margin-top: 500px !important;
  }
  .metro5 {
    margin-top: 150px !important;
  }
  .metroParagraph2 {
    margin-top: 0px !important;
  }
  .metroParagraph3 {
    margin-top: -75px !important;
  }
  .metroParagraph4 {
    margin-top: -950px !important;
  }
  .metroParagraph5 {
    margin-top: -875px !important;
  }
  .metroParagraph6 {
    margin-top: 0px !important;
  }
  .metroParagraph7 {
    margin-top: -75px !important;
  }
  .metroParagraph8 {
    margin-top: -150px !important;
  }
  #metro .video-wrapper {
    margin-top: -200px !important;
  }
}

@media only screen and (max-width: 800px) and (min-width: 650px) {
  .metro4 {
    margin-top: 400px !important;
  }
  .metro5 {
    margin-top: 100px !important;
  }
  .metroParagraph4 {
    margin-top: -1100px !important;
  }
  .metroParagraph5 {
    margin-top: -1025px !important;
  }
  .metroParagraph6 {
    margin-top: 0px !important;
  }
  .metroParagraph7 {
    margin-top: -75px !important;
  }
  #metro .video-wrapper {
    margin-top: -200px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 801px) {
  .metro2 {
    margin-top: 100px !important;
  }
  .metro3 {
    margin-top: 0px !important;
  }
  .metro4 {
    margin-top: 300px !important;
  }
  .metro5 {
    margin-top: 200px !important;
  }
  .metroParagraph4 {
    margin-top: -1300px !important;
  }
  .metroParagraph5 {
    margin-top: -1300px !important;
  }
  .metroVideo1 {
    margin-top: -200px !important;
  }
}

/* ----- ----- ---- ----- */
/* VERONICA               */
/* ----- ----- ---- ----- */

#veronica * {
  color: #2B2D30;
}

@media only screen and (max-width: 800px) {
  .vs1 {
    margin-top: 150px !important;
  }
  .vs2 {
  }
  .vs3 {
    margin-top: 150px !important;
  }
  .vs4 {
    margin-top: 500px !important;
  }
  .vs5 {
    margin-top: 0px !important;
  }
  .vs6 {
    margin-top: 100px !important;
  }
  .vsParagraph2 {
  }
  .vsParagraph3 {
    margin-top: -75px !important;
  }
  .vsParagraph4 {
  }
  .vsParagraph5 {
    margin-top: -75px !important;
  }
  .vsParagraph6 {
    margin-top: -975px !important;
  }
  .vsParagraph7 {
    margin-top: -900px !important;
  }
  .vsParagraph8 {
  }
  .vsParagraph9 {
    margin-top: -75px !important;
  }
  .vsParagraph10 {
    margin-top: -75px !important;
  }
  .vsParagraph11 {
    margin-top: -75px !important;
  }
  .vsParagraph12 {
  }
  .vsParagraph13 {
    margin-top: -75px !important;
  }
  .container {
    margin-top: -150px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 801px) {
  .vsParagraph1 {
    margin-top: 50px !important;
  }
  .vsParagraph6 {
    margin-top: -1200px !important;
  }
  .vsParagraph7 {
    margin-top: -1200px !important;
  }

  .vs4 {
    margin-top: 400px !important;
  }
  .vs5 {
    margin-top: 0px !important;
  }
  .vs6 {
    margin-top: 100px !important;
  }
  .vs9 {
    margin-top: 150px !important;
  }
  .vs10 {
    margin-top: 150px !important;
  }
  .container {
    margin-top: -150px !important;
  }
}

/* ----- ----- ---- ----- */
/* SDA               */
/* ----- ----- ---- ----- */

#sda * {
  color: #2B2D30;
}

@media only screen and (max-width: 800px) {
  .sda4 {
    margin-top: 0px !important;
  }
  .sda5 {
    margin-top: 150px !important;
  }
  .sdaParagraph2 {
    margin-top: -25px !important;
  }
  .sdaParagraph3 {
    margin-top: -75px !important;
  }
  .sdaParagraph5 {
    margin-top: -75px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 801px) {
  .sda3 {
    margin-top: 200px !important;
  }
  .sdaParagraph1 {
    margin-top: 50px !important;
  }
}

/* ----- ----- ---- ----- */
/* Roamio               */
/* ----- ----- ---- ----- */

#roamio .video-wrapper {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 12);
}

#roamio .video-wrapper iframe {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 12);
  border: none;
}

@media only screen and (max-width: 800px) {
  .roamio1 {
    margin-top: 100px !important;
  }
  .roamio2 {
    margin-top: 500px !important;
  }
  .roamio3 {
    margin-top: 150px !important;
  }
  .roamio4 {
    margin-top: 50px !important;
  }
  .roamio5 {
    margin-top: 500px !important;
  }
  .roamio6 {
    margin-top: 150px !important;
  }
  .roamio8 {
    margin-top: -75px !important;
  }
  .roamioParagraph2 {
    margin-top: -800px !important;
  }
  .roamioParagraph3 {
    margin-top: -725px !important;
  }
  .roamioParagraph4 {
    margin-top: -50px !important;
  }
  .roamioParagraph5 {
    margin-top: -75px !important;
  }
  .roamioParagraph6 {
    margin-top: -850px !important;
  }
  .roamioParagraph7 {
    margin-top: -750px !important;
  }
  .roamioParagraph8 {
    margin-top: -50px !important;
  }
  .roamioParagraph9 {
    margin-top: -75px !important;
  }
  .roamioVideo1 {
    margin-top: -100px !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 801px) {
  .roamioParagraph1 {
    margin-top: 50px !important;
  }
  .roamio1 {
    margin-top: 150px !important;
  }
  .roamio2 {
    margin-top: 400px !important;
  }
  .roamio3 {
    margin-top: 250px !important;
  }
  .roamio4 {
    margin-top: 50px !important;
  }
  .roamio5 {
    margin-top: 500px !important;
  }
  .roamio6 {
    margin-top: 300px !important;
  }
  .roamioParagraph2 {
    margin-top: -800px !important;
  }
  .roamioParagraph3 {
    margin-top: -800px !important;
  }
  .roamioParagraph6 {
    margin-top: -850px !important;
  }
  .roamioParagraph7 {
    margin-top: -850px !important;
  }
  .roamioVideo1 {
    margin-top: -100px !important;
  }

}


/* ----- ----- ---- ----- */
/* Veronica               */
/* ----- ----- ---- ----- */

#veronica .container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
#veronica .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* ----- ----- ---- ----- */
/* Motion               */
/* ----- ----- ---- ----- */


#motion .video-wrapper {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 9);
  margin-bottom: 50px;
}

#motion .video-wrapper iframe {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 9);
  border: none;
}

@media only screen and (max-width: 1200px) {
  #motion #header {
    flex-direction: column;
    width: 100%;
  }
}


/* ----- ----- ---- ----- */
/* MEDIA QUERIES          */
/* ----- ----- ---- ----- */

@media only screen and (max-width: 1200px) {
  .project-footer {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .video-wrapper {
    width: 90vw !important;
    transform: translateX(calc((-100% + var(--grid-width)) + ((100vw - var(--grid-width)) / 2))) !important;
  }
  .video-wrapper iframe {
    min-height: 50vw;
  }
}

@media only screen and (max-width: 800px) {
  .project-text .title, .project-text .info, .project-text .description {
    flex: 0 0 100%;
    margin-bottom: 25px;
    margin-left: 0;
  }
  .project-footer {
    display: none;
  }
  p {
    font-size: 18px;
  }
  img {
    width: 100%;
  }
  #main-spacer {
    height: calc(var(--unit) * 0);
  }
  .project {
    margin-bottom: 0;
  }
}



/* ----- ----- ---- ----- */
/* KEYFRAMES              */
/* ----- ----- ---- ----- */

@keyframes textFadeUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes imageFadeRight {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}



/* ----- ----- ---- ----- */
/* FULLSCREEN IMAGE       */
/* ----- ----- ---- ----- */

:root {
  --unit: 50px;
  --grid-width: calc(22 * var(--unit));
}

.img-fullscreen {
  width: 100vw !important;
  transform: translateX(calc((-100% + var(--grid-width)) + ((100vw - var(--grid-width)) / 2) - 17px)) translateY(-200px);
  margin-bottom: -100px;
  margin-top: 100px;
}


@media only screen and (max-width: 1200px) {
  :root {
    --grid-width: calc(14 * var(--unit));
  }
  
  .img-fullscreen-tablet {
    width: 75vw !important;
    transform: translateX(calc((-100% + var(--grid-width)) + ((75vw - var(--grid-width)) / 2) - 25px)) translateY(-200px);
    margin-bottom: -100px;
    margin-top: 100px;
  }


}

@media only screen and (max-width: 800px) {
  :root {
    --grid-width: calc(100% - 50px);
  }

  .img-fullscreen, .img-fullscreen-tablet {
    transform: translateX(calc((-100% + var(--grid-width)) + ((100vw - var(--grid-width)) / 2) - 50px)) translateY(-200px);
  }

  .img-fullscreen-mobile {
    width: 75vw !important;
    transform: translateX(calc((-100% + var(--grid-width)) + ((100vw - var(--grid-width)) / 2) - 50px)) translateY(-200px);
    margin-bottom: -100px;
    margin-top: 100px;
  }
}