/* ----- ----- ----- ----- */
/* PAGE STYLES - GENERAL   */
/* ----- ----- ----- ----- */

.row {
  margin-bottom: calc(var(--unit) * 2);
  width: calc(100% - var(--unit));
  display: flex;
  flex-wrap: wrap;
}

.row .col:last-child {
  margin-right: 0 !important;
}

.col-3 {
  flex: 0 0 15%;
  margin-right: 25px;
}

.col-4 {
  flex: 0 0 20%;
  margin-right: var(--unit);
}

.col-5 {
  flex: 0 0 25%;
  margin-right: var(--unit);
}

.col-6 {
  flex: 0 0 30%;
  margin-right: var(--unit);
}

.col-8 {
  flex: 0 0 40%;
  margin-right: var(--unit);
}

.col-10 {
  flex: 0 0 45%;
  margin-right: var(--unit);
}

.col-12 {
  flex: 0 0 60%;
  margin-right: var(--unit);
}

.col-15 {
  flex: 0 0 75%;
  margin-right: var(--unit);
}

.col-16 {
  flex: 0 0 80%;
  margin-right: var(--unit);
}

.col-20 {
  flex: 0 0 100%;
  margin-right: 0;
}

.fake-link {
  text-decoration: underline !important;
  transition-duration: 0.25s;
}

.fake-link:hover {
  color: #E52354;
}

.text-right {
  text-align: right;
}

/* ----- ----- ---- */
/* REEL             */
/* ----- ----- ---- */

#reel #header {
  display: flex;
  width: calc(var(--unit) * 16);
  justify-content: space-between;  
  margin-bottom: var(--unit);
}

#reel #header h1 {
  margin-right: 150px;
}

#reel .video-wrapper {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 9);
}

#reel .video-wrapper iframe {
  width: var(--grid-width);
  height: calc( (var(--grid-width) / 16) * 9);
  border: none;
}

@media only screen and (max-width: 1200px) {
  #reel #header {
    flex-direction: column;
    width: 100%;
  }
}


/* ----- ----- ---- */
/* CONTACT          */
/* ----- ----- ---- */

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

#contact #header {
  display: flex;
  align-items: baseline;
  width: calc(var(--unit) * 8);
  justify-content: space-between;  
  margin-bottom: var(--unit);
}

#contact #content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#contact #content #form {
  flex: 0 0 59%;
  margin-right: var(--unit);
}

#contact #details {
  margin-bottom: var(--unit);
}

#contact #details p {
  margin-bottom: calc(var(--unit)/2);
}

#contact p, #contact li {
  line-height: 18px;
}

#contact .status-block {
  background-color: rgba(255, 255, 255, 0.5);
  padding: var(--unit);
  min-height: calc(var(--unit) * 3);
}

#contact .status-block div {
  margin-bottom: calc(var(--unit)/2);
}

#contact .status-block button {
  background-color: transparent;
  border: 1px solid white;
  padding: calc(var(--unit)/4);
  width: calc(var(--unit) * 2);
  color: white;
}

#contact .status-block .saved-message {
  background-color: white;
  color: black;
  padding: calc(var(--unit)/2);
  margin: calc(var(--unit)/2) 0;
}

/* FORM */

#contact-form {
  display: flex;
  flex-direction: column;
}

#contact-form h3 {
  margin: 0;
}

#contact-form .input-wrapper {
  width: calc(100% - 16px);
  position: relative;
}

#contact-form input, #contact-form textarea {
  padding: 8px;
  margin-bottom: calc(var(--unit)/2);
  color: black;
  width: 100%;
}

#contact-form textarea {
  height: 125px;
}

#contact-form button {
  height: var(--unit);
  width: calc(var(--unit) * 4);
}

#contact-form input, #contact-form textarea, #contact-form button {  
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
}

.invalid::after, .valid::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 29px;
  margin-left: 5px;
}

.invalid::after {
  background-image: url("../svg/alert.svg");
}

.valid::after {
  background-image: url("../svg/confirm.svg");
}

@media only screen and (max-width: 1200px) {
  #contact #content {
    flex-direction: column-reverse;
  }

  #contact #content #form {
    flex: 0 0 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 800px) {
  #contact #header {
    flex-direction: column;
  }
}


/* ----- ----- ---- */
/* ABOUT            */
/* ----- ----- ---- */

.aboutList p {
  padding-bottom: 0px !important;
}

#about {
  padding: 0 calc(var(--unit)/2);
  margin-bottom: -300px;
}

#about h1 {
  font-size: 35px;
  line-height: 55px;
  font-family: 'univers_lght';
  font-weight: 300;
  color: white;
}

#about p {
  padding-bottom: var(--unit);
}

#about label {
  display: block;
  padding-top: var(--unit);
}

#list {
  justify-content: center;
}

#list li {
  margin-bottom: 20px;
  line-height: 26px;
}

#edu-exp {
  width: 100%;
  margin-left: calc(var(--unit));
  margin-bottom: calc( var(--unit) * -2);
}

#edu-exp h1 {
  display: block;
  height: calc( var(--unit) * 2);
  margin-bottom: calc( var(--unit) * 2);
}

#edu-exp .item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: calc( var(--unit) * 2);
  margin-bottom: calc( var(--unit) * 2);
}

#edu-exp .item img {
  display: block; 
  flex: 0 0 50%; 
}

#edu-exp div { 
  display: block; 
  flex: 0 0 50%; 
  text-align: right;
}

#edu-exp .item p { 
  display: block;
  margin: 0;
  padding: 0;
}

.collapse-1 { display: block !important; }


/* ----- ----- ---- ----- */
/* KEYFRAMES              */
/* ----- ----- ---- ----- */

.fadeUp {
  animation: textFadeUp 0.5s forwards;
  opacity: 0;
}

@keyframes textFadeUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ----- ----- ---- ----- */
/* MEDIA QUERIES          */
/* ----- ----- ---- ----- */

/* 
desktop: 1920-1200
tablet: 1200-750
mobile: 750-- 
*/

/* tablet styling */
@media only screen and (max-width: 1200px) {

  #about {
    margin-bottom: -100px;
  }

  .row {
    margin-bottom: calc(var(--unit) * 2);
  }

  #list {
    /* width: calc( var(--unit) * 15); */
    justify-content: left;
  }

  #list h1 { 
    text-align: left;
    width: 80%; 
  }
  #list .col {
    margin-bottom: calc(var(--unit) * 2);
  }

  .col-3, .col-4, .col-5, .col-6 {
    flex: 0 0 50%;
    margin-right: 0;
  }
  
  .col-8, .col-15, .col-16 {
    flex: 0 0 100%;
    margin-right: 0;
  }

  .collapse-2 {
    display: block !important;
  }

  #edu-exp {
    margin-left: 0;
  }

  #edu-exp .item p:first-child::after { content: ""; }
}

@media only screen and (max-width: 800px) {
  #about {
    margin-bottom: -100px;
  }
  
  .likesIntro {
    font-size: 28px !important;
    line-height: 40px !important;
  }

  .row {
    margin-bottom: 0
  }

  #list h1 { 
    text-align: left;
    width: 80%; 
  }

  #list li {
    font-size: 18px;
  }

  .col {
    margin-bottom: calc(var(--unit) * 2);
  }

  .col-3, .col-4, .col-5, .col-6, .col-8, .col-15, .col-16  {
    flex: 0 0 100%;
    margin-right: 0;
  }

  .collapse-2 {
    display: block !important;
  }


  .item img { width: 100%; }
  .item { margin-bottom: 0 !important;}
  
  #edu-exp div { 
    width: 100%;
    text-align: left;
    padding-bottom: var(--unit);
    flex: 0 0 100%;
  }
}